import React from "react"
import BackupTypesSlider from "./_backupTypesSlider";
import CaseStudyList from "../case-study/_caseStudyList";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'

const BaobabPage = ({data}) => (
  <Layout.Layout>
    <Layout.SEO refKey="baobab" />

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <h1 className="font-heading text-4xl md:text-5xl leading-tight font-normal text-dark">
              Protect your business against cyber attacks with <span className="font-black text-primary">Baobab</span> and <span className="font-black">SimpleBackups</span>.
            </h1>
            <p className="mt-6">Automate all your server, database, website, snapshot and cloud storage backups.</p>
          </div>
          <div className="mt-24">
            <div><Ui.Quote>SimpleBackups is a proud partner of Baobab. Together we'll insure and protect you from cyber attacks.</Ui.Quote></div>
            <div className="flex items-center justify-center mt-6">
              <Ui.Image
                filename="baobab.png"
                alt="baobab"
                style={{maxWidth: 280}}
              />
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="">
      <Ui.Container>
        <Ui.Row>
          <div className="">
            <Ui.Typography tag="h2" className="text-center">Protecting your business with the right backup solution</Ui.Typography>
            <BackupTypesSlider />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center mb-24">Hello Baobab users...<br/>We have something for you!</Ui.Typography>
          <Ui.FeatureRows items={
            [
              {
                title: "Preferred pricing",
                description: (
                    <>
                      <p>
                        SimpleBackups and Baobab are partnering up to  offer you 15% discount on any SimpleBackups plan.<br/>
                        No string attached, and always with our premium support!
                      </p>
                    </>
                ),
                image: (<div className=""><Ui.Image
                    filename='simplebackups-plus-baobab.png'
                    alt="simplebackups and baobab partnership"
                /></div>)
              },
              {
                title: "Get started in minutes",
                description: (
                  <>
                    <p>
                      Connect your servers, databases, storage and get your data protected in just a few minutes.<br/>
                      <Ui.Link target="https://docs.simplebackups.com/">Documentation</Ui.Link>, and support is just a click a way.
                    </p>
                  </>
                ),
                image: (<Ui.Image
                  data-aos="fade-left"
                  filename="simplebackups-schedule.png"
                  alt="digitalocean hourly backups"
                  style={{maxWidth: 400}}
                />)
              },
            ]}
          />
          <div className="mt-20 text-center"><Ui.Link className="ml-4" arrow="right" to="/features">View all features</Ui.Link></div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <Ui.Typography tag="h2" className="text-center">Small and medium sized business work with us</Ui.Typography>
            <p className="mb-6 text-center">We focus on keeping things simple and provide the best support possible to <u>every</u> user.</p>

            <CaseStudyList size="sm" posts={data.posts.edges}></CaseStudyList>

            <Sections.SectionTestimonialExtended />

          </div>
          <p className="text-center"><Ui.Link color="primary" to="/case-study/" arrow="right">What they say about us</Ui.Link></p>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>


  </Layout.Layout>
)

export default BaobabPage