import React, {useState} from "react"
import {Link} from "components/ui"
import { Link as LinkBase} from "gatsby";
import Image from "components/image"
import classnames from "classnames"

const websiteContent = (<div className="bg-gray-200 rounded-2xl p-8 md:p-16 md:flex overflow-hidden">
  <div className="flex-1 md:pr-20">
    <p className="text-4xl font-bold mb-6">Website Backup</p>
    <p className="text-gray-500">
      Use Simple Backups to orchestrate all your websites backup.<br/>
      Establish which files and databases have to be backed up, organise
      them by project and have a clear, centralized view of all your
      running backups.
    </p>
    <p className="my-8 font-bold">
      <Link to="/server-backup" underline={false}>More about website backup<i className="fa fa-arrow-right ml-2 text-sm"></i></Link>
    </p>

    <ul className="">
      <li><i className="fa-solid fa-circle-1 mr-3"></i> Connect your Server</li>
      <li><i className="fa-solid fa-circle-2 mr-3"></i> Select the folders you want to back up</li>
      <li><i className="fa-solid fa-circle-3 mr-3"></i> Connect your Storage</li>
      <li><i className="fa-solid fa-circle-4 mr-3"></i> Schedule & forget it</li>
    </ul>
  </div>
  <div className="md:block hidden relative w-2/5 rounded-xl -mb-16 flex align-items-end" >
    <div className="absolute bottom-0 shadow-2xl" style={{'width': '450px', 'height': '400px'}}>
      <Image filename="landing-website-backup.png" alt="Website backup with simple backups" className="rounded-lg rounded-b-none"></Image>
    </div>
  </div>
</div>)

const databaseContent = (<div className="bg-gray-200 rounded-2xl p-8 md:p-16 flex overflow-hidden">
  <div className="flex-1 md:pr-20">
    <p className="text-4xl font-bold mb-6">Database Backup</p>
    <p className="text-gray-500">
      Backup your databases with just a few clicks.<br/>
      We support tunneling, Serverless backup, integration with all database-as-a-service solution, custom flag and much more.<br/>
    </p>
    <p className="text-gray-500 flex my-6">
      <div className="flex gap-10 items-center justify-center">
        <LinkBase to="/mysql-backup/"><Image filename="mysql-ico.png" className="w-12"/></LinkBase>
        <LinkBase to="/postgresql-backup/"><Image filename="postgresql-ico.png" className="w-12"/></LinkBase>
        <LinkBase to="/mongodb-backup/"><Image filename="icons/mongodb-ico.png" className="w-12"/></LinkBase>
      </div>
    </p>

    <ul className="mt-4">
      <li><i className="fa-solid fa-circle-1 mr-3"></i> Connect your Database</li>
      <li><i className="fa-solid fa-circle-2 mr-3"></i> Select your storage</li>
      <li><i className="fa-solid fa-circle-3 mr-3"></i> Schedule & forget it</li>
    </ul>
  </div>
  <div className="md:block hidden relative w-2/5 rounded-xl -mb-16 flex align-items-end  " >
    <div className="absolute bottom-0 shadow-2xl" style={{'width': '450px', 'height': '400px'}}>
      <Image filename="landing-db-backup.png" alt="Database backup with simple backups" className="rounded-lg rounded-b-none"></Image>
    </div>
  </div>
</div>);

const snapshotContent = (<div className="bg-gray-200 rounded-2xl p-8 md:p-16 md:flex overflow-hidden">
  <div className="flex-1 md:pr-20">
    <p className="text-4xl font-bold mb-6">Server & Volume Snapshot</p>
    <p className="text-gray-500">
      Connect any Provider, select the instances you want to automate Snapshots for.<br/>
      We take care of the rest: we notify you, take care of rotating your Snapshots based on your policies, always keep .<br/>
      We help you make sure your server and volumes are backed up while keeping your cost down.
    </p>

    <p className="my-8 font-bold">
      <Link to="/snapshots/" underline={false}>More about server & volume snapshots<i className="fa fa-arrow-right ml-2 text-sm"></i></Link>
    </p>

    <ul className="">
      <li><i className="fa-solid fa-circle-1 mr-3"></i> Connect your Provider</li>
      <li><i className="fa-solid fa-circle-2 mr-3"></i> Select your server or volume</li>
      <li><i className="fa-solid fa-circle-3 mr-3"></i> Schedule & forget it</li>
    </ul>
  </div>
  <div className="md:block hidden relative w-2/5 rounded-xl -mb-16 flex align-items-end  " >
    <div className="absolute bottom-0 shadow-2xl" style={{'width': '450px', 'height': '335px'}}>
      <Image filename="landing-snapshot-backup.png" alt="Snapshot backup with simple backups" className="rounded-lg rounded-b-none"></Image>
    </div>
  </div>
</div>);

const cloudContent = (
  <div className="bg-gray-200 rounded-2xl p-8 md:p-16 md:flex overflow-hidden">
    <div className="flex-1 md:pr-20">
      <p className="text-4xl font-bold mb-6">Storage Replication</p>
      <p className="text-gray-500">
        Automatically synchronize your buckets and cloud storage across providers.<br/>
        Secure your most precious data by scheduling and automating replication of your buckets to another storage.
      </p>

      <p className="my-8 font-bold">
        <Link to="/storage-backup/" underline={false}>More about Cloud Storage backup<i className="fa fa-arrow-right ml-2 text-sm"></i></Link>
      </p>

      <ul className="">
        <li><i className="fa-solid fa-circle-1 mr-3"></i> Connect your Providers</li>
        <li><i className="fa-solid fa-circle-2 mr-3"></i> Select the source storage</li>
        <li><i className="fa-solid fa-circle-2 mr-3"></i> Select the destination storage</li>
        <li><i className="fa-solid fa-circle-3 mr-3"></i> Schedule & forget it</li>
      </ul>
    </div>
    <div className="md:block hidden relative w-2/5 rounded-xl -mb-16 flex align-items-end  " >
      <div className="absolute bottom-0 shadow-2xl" style={{'width': '450px', 'height': '340px'}}>
        <Image filename="landing-replication-backup.png" alt="Storage Replication backup with simple backups" className="rounded-lg rounded-b-none"></Image>
      </div>
    </div>
  </div>
);

const contents = {
  'website' : websiteContent,
  'database' : databaseContent,
  'snapshot' : snapshotContent,
  'cloud' : cloudContent
}

const BackupTypesSlider = () => {
  const [current, setCurrent] = useState('website')
  let types = [
    {key: 'website', value: 'Website Backups'},
    {key: 'database', value: 'Database Backups'},
    {key: 'snapshot', value: 'Server & Volume Snapshots'},
    {key: 'cloud', value: 'Storage Replication'},
  ]

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-12 w-100 mb-12 mt-12 md:mt-20 font-medium text-lg">
        { types.map(function (d, idx) {
          return (<div onClick={() => setCurrent(d.key)} key={idx} className={classnames({'cursor-pointer border-t-4 py-3 block': true, 'border-primary': current === d.key})}>{d.value}</div>)
        })}
      </div>

      <div>{contents[current]}</div>
    </>
  )
}

export default BackupTypesSlider;